.rectangle {
  width: 268px;
  height: auto;
  background-color: #fff;
  border: 1px solid #000;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  font-family: 'Roboto',
      sans-serif !important;
}

.normRect {
  width: 250px;
  height: 340px;
  background-color: #fff;
  border: 1px solid #000;
  margin: 12px;
  text-align: center;
  border-radius: 10px;
  font-family: 'Roboto',
      sans-serif !important;
}

.Bigrectangle {
  height: auto !important;
  width: 250px;
  background-color: #fff;
  border: 1px solid #000;
  /* margin: 10px; */
  text-align: center;
  border-radius: 10px;
  font-family: 'Roboto',
      sans-serif !important;
}

.splitRect {
  padding: 0% !important;
  width: auto !important;
  border-bottom: 0.8px solid #ccc;
  word-wrap: break-word;
}

.splitTop {
  padding: 0% !important;
  width: auto !important;
  border-top: 0.8px solid #ccc;
}

.center {
  text-align: center;
}

.margin {
  text-align: center;
  padding: 5% !important;
  /* padding-bottom: 12% !important; */
}

.page-break {
  page-break-before: always !important;
  display: none !important;
}
@media print {
  .page-break {
    display: block !important;
  }
}

#QRcode {
  font-family: 'Roboto',
    sans-serif !important;
  display: -ms-inline-grid;
  flex-direction: row;
}

.reset * {
  font-family: revert !important ;
  font-size: 13px;
}