.custom-report-list-div {
    font-size: 20px;
    text-align: center;
    margin-top: 60px;
}


.custom-report-search>form>input {
    border: 1px solid steelblue !important;
    font-size: 15px;
    padding: 5px;
}

.custom-report-search>form>span {
    padding-left: 8px;
    margin-right: 25px;
}

.custom-report-main-heading {
    display: flex;
    width: 89%;
    justify-content: space-between;
    margin: auto;
    margin-top: 100px;
}

.custom-report-table {
    width: 90%;
    margin-top: 50px;
    margin: auto;
}

.custom-report-table-heading {
    background-color: steelblue !important;
    height: 50px;
    width: 70px;
    height: 50px;
}

.custom-report-name {
    height: 50px;
    padding: 8px;
}

.preview-report-btn {
    border: none;
    color: #2D7DC1;
    background: none;
}


