@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

* {
  font-size: 14px;
  font-family: "Poppins", "Calibri", "Times New Roman", sans-serif ;
  /* overflow-x: hidden !important; */
  /* overflow-y: hidden !important; */
}
body {
  overflow-x: hidden;
}
/* media query for page responsiveness */

.radioButtons {
  height: 15px;
  width: 15px;
}

input,
select,
table,
th,
td > input {
  text-align: left;
  width: auto;
  padding: 2px;
  border-radius: 5px;
  /* border-color: rgb(218, 216, 216) !important; */
  /* border-style: solid; */
}

input:hover {
  border-color: 1px #487fad !important;
}

input:focus {
  border-color: 1px #487fad !important;
}

.table-bordered {
  border-color: rgb(215, 213, 213);
}

.actionColumn {
  width: 12% !important;
}

tr:hover {
  background-color: #dce0e0e0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.nav-link {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000 !important;
}

.nav-link:hover {
  background-color: #69fae1;
  border-radius: 8px;
  font-weight: bolder !important;
}

/* header tabs active */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: steelblue !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #000 !important;
  background-color: #69fae1 !important;
}
.nav-tabs .nav-link.active {
  color: #000 !important;
  background-color: #69fae1 !important;
  border-color: gray !important;
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: #cffaf3 !important;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-item {
  margin-bottom: 5px;
}
.toolTip {
  background-color: steelblue !important;
  opacity: 0.5 !important;
  font-size: small;
  /* width: auto; */
  /* padding: 6px; */
  border-radius: 15px !important;
  -webkit-text-fill-color: #fff !important;
}
.solid{
  opacity: 1 !important;
}

#toolTip,
#reportTip,
#updateTip,
#deleteTip,
#assignTip,
#contactTip,
#uploadTip,
#assignTip,
#stopTip,
#activeTip,
#viewTip,
#assignTip2,
#siteTip,
#attacTip,
#assignTip1 {
  background-color: steelblue !important;
  /* opacity: 0.5 !important; */
  z-index: 1;
  font-size: small;
  width: auto;
  padding: 6px;
  border-radius: 8px;
  -webkit-text-fill-color: #fff !important;
}

#openCloseCal,
#CloseCal {
  background-color: steelblue !important;
  opacity: 1.5 !important;
  font-size: small;
  width: auto;
  padding: 6px;
  border-radius: 8px;
  -webkit-text-fill-color: #fff !important;
}

.highlighted {
  background-color: yellow;
}
.normal {
  background-color: #fff !important;
}
/* .noNegative{
  initial-value: 1 !important;
} */
.saveButton {
  background-color: #69fae1 !important;
  cursor: pointer !important;
  border: 1px solid #69fae1;
  border-radius: 10px;
  padding: 8px;
  color: #000;
  font-weight: bold;
  width: 85px;
}

.saveButton:hover {
  border: 1px solid steelblue !important;
}

.backButton {
  background-color: steelblue !important;
  border: 1px solid steelblue;
  cursor: pointer !important;
  border-radius: 10px;
  padding: 8px;
  color: #fff;
  font-weight: 600;
  width: 85px;
}

.backButton:hover {
  border: 1px solid black !important;
}

.headershadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2) !important;
  padding-top: 0%;
}
.header:hover {
  background-color: #f8f9fa !important;
  font-size: "16px";
}
.navbar {
  padding: 0% !important;
  position: fixed !important;
  z-index: 9999;
  width: 100%;
}

.navbar-toggler {
  padding: 0% !important;
}

.navbar-toggler-icon {
  display: none !important;
}

.nav-tabs .nav-link.active {
  color: #000 !important;
  /* background-color: lightsteelblue !important; */
  border-color: gray !important;
}

.card {
  border-radius: 15px !important;
  background-color: #f8fffe !important;
}

.display-none {
  color: transparent !important;
}

.delIcons {
  color: steelblue;
  background-color: white !important;
  float: inline-end !important;
  border: none !important;
  border-radius: 5px;
  /* padding: 5px; */
}

.buttons {
  background-color: #e8f5f3 !important;
  cursor: pointer !important;
  border: 1px solid #e8f5f3;
  border-radius: 10px;
  padding: 6px;
  color: #000;
  width: 120px;
}

.buttons:hover {
  border: 1px solid steelblue;
}

.addBtn {
  background-color: rgb(120, 183, 235) !important;
  cursor: pointer !important;
  border: 1px solid #e8f5f3;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  color: #000;
  width: auto;
}

.dropdown-menu {
  /* width: 5% !important ; */
  --bs-dropdown-border-radius: 0.375rem;
  padding-top: 0.5% !important;
  padding-bottom: 0 !important;
}

.dropdwn {
  padding: 3% !important;
  margin-left: 12%;
}

.dropdwn:hover {
  background-color: #cbeee9 !important;
  border-radius: 8px;
  --bs-dropdown-min-width: 8rem;
}

.addBtn:hover {
  border: 1px solid #69fae1;
}

.statusCards:hover {
  border: 1px solid #f8fffe !important;
  background: #fefefe;
}
.logoutBtn:hover {
  background-color: #69fae1 !important;
  border-radius: 8px;
  font-weight: bolder !important;
}

.table-bordered {
  word-wrap: break-word;
}

.daysAlign {
  margin-left: 35px;
  margin-top: 20px;
  height: 15px;
  width: 15px;
}

.hoursAlign {
  margin-left: 42px;
  margin-top: 20px;
  height: 15px;
  width: 15px;
}

.accordianHeader {
  padding-left: 8px !important;
  font-weight: 100 !important;
  padding-top: 8px;
  font-size: 25px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}


.listHeadings {
  padding-top: 60px;
  text-align: center;
  font-weight: bolder;
}

.formHeadings {
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
}
.statusHeadings {
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: midnightblue;
}
.statusCards:hover {
  background-color: #ccc;
  border: 1px solid #cccc !important;
  border-radius: 12px !important;
}
.freezeHeader {
  position: fixed !important;
  z-index: 9999;
  width: 100%;
}

.card-container {
  padding-top: 15px !important;
  /* background: #E8F0FEFF; */
  width: 100%;
  height: 100%;
}

.SignInButton {
  font-weight: bold;
  padding: 6px 12px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(105, 250, 225, 1) 59%,
    rgba(72, 127, 173, 1) 100%
  );
}

.dashBoardCard {
  font-weight: bold;
  /* padding: 6px 12px; */
  width: 17rem;
  border-radius: 10px;
  height: 8rem;
  border-radius: 20px;
}
.cardText {
  font-size: 15px;
  color: darkslateblue;
}
.bolderText {
  font-weight: bolder;
}
.dashBoardCard:hover {
  color: rgb(51, 45, 113);
  border: 1px solid steelblue;
}
.icons {
  color: steelblue;
}
#preloader {
	position: fixed;
	height: 100vh;
	width: 100%;
	z-index: 5000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	/* change if the mask should have another color then white */
	z-index: 10000;
}
.list-wrapper {
  position: relative;
  margin-left: 12px !important;
}
.list-item-wrapper {
  margin-top: 10px;
  position: relative;
}
.list-bullet {
  /* float: left; */
  /* display: flex; */
  margin-right: 22px;
  margin-top: 1%;
}
.list-item {
  display: table-row;
  vertical-align: middle;
}
.list-title {
  font-weight: 800;
}
.list-text {
  font-weight: 400;
}
.red-line {
  background: steelblue;
  z-index: -1;
  width: 0.8px;
  height: 7vh;
  position: absolute;
  left: 5.5px;
}
.white-line {
  background: #fff;
  z-index: -1;
  top: 0px;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 15px;
}
.validity{
  background-color: #d0e3f2;
  border: none;
  padding: 5%;
  border-radius: 8px;
}
.descriptn{
  background-color: #d0e3f2;
    border: none;
  border-radius: 8px;
  resize: none;
  height: 155px;
  width:380px
}