.accordion-button {
  background-color: white !important;
  padding: 0 !important;
  color: black !important;
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: normal !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  color: #212529;
}
.accordion .nav-link {
  font-size: 14px !important;
}
/* .header:hover{
  background-color: #F8F9FA !important;
  color: steelblue !important;
  font-weight: bold;
} */
.sideNavIcons{
  padding-left: 2px !important;
  padding-right: 6px;
  color: slategray;
  height: 15px;
}


.offcanvas-responsive-div {
  width: 80vw !important; /* Default width for small screens */
  max-width: 400px;
  background-color: "white";
}

@media (min-width: 700px) { 
  .offcanvas-responsive-div {
    width: 30vw !important;
  }
}

@media (min-width: 1200px) {
  .offcanvas-responsive-div {
    width: 20vw !important;
  }
}