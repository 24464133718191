/* App.css */

body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.tab {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  border: none;
  background: none;
  outline: none;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #eee;
}

.tab.active {
  background-color: #ddd;
}

.content {
  padding: 10px;
  background-color: white;
}

.asset-details {
  margin-top: 20px;
}

.asset-image {
  text-align: center;
  margin-bottom: 20px;
}

.asset-image img {
  max-width: 100%;
  height: auto;
}

.asset-field {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.asset-label {
  font-weight: bold;
  color: #555;
}

.asset-value {
  text-align: right;
  color: #888;
}

.group {
  margin-bottom: 30px;
  border: 1px solid #888;
  padding: 2rem;
  border-radius: 1rem;
}

.group h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  color: #333;
  font-weight: bolder;
}

/* Responsive styles */
@media (max-width: 480) {
  .tabs {
    flex-direction: column;
  }

  .tab {
    padding: 10px;
  }

  .content {
    padding: 10px;
  }

  .asset-field {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .asset-value {
    text-align: left;
  }

  .group h3 {
    font-size: 1em;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
}

.modal-content img {
  max-width: 100%;
  height: auto;
}

.modal-close,
.modal-zoom {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: rgb(253, 253, 253);
  cursor: pointer;
  outline: none;
}

.modal-zoom {
  right: 40px;
}

.modal-zoom:first-of-type {
  right: 70px;
}
