/* Container for the table */
.tableContainer {
  width: 100%;
  margin: 20px auto;
  overflow-x: auto;
}

/* Basic table styling */
.verificationTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

/* Table header styling */
.verificationTable th {
  padding: 12px 15px;
  text-align: left;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
}

/* Table body styling */
.verificationTable td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

/* Comment block styling */
.comment {
  margin-bottom: 4px;
}
/* Responsive font size */
@media (max-width: 768px) {
  .verificationTable th,
  .verificationTable td {
    padding: 10px;
    font-size: 14px;
  }
}

/* Mobile responsive styling */
@media (max-width: 576px) {
  .verificationTable th,
  .verificationTable td {
    padding: 8px;
    font-size: 12px;
  }
}

/* Timeline container */
.timelineContainer {
  width: 100%;
  margin: 10px auto;
  position: relative;
  padding-left: 10px;
  border-left: 2px solid #ddd;
}

/* Timeline item */
.timelineItem {
  margin-bottom: 20px;
  position: relative;
  padding-left: 20px;
}

/* Date styling */
.timelineDate {
  font-weight: bold;
  color: gray;
  margin-bottom: 2px;
  font-style: italic;
}

/* Content styling */
.timelineContent {
  background-color: #f9f9f9;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Verified by */
.verifiedBy {
  /* font-style: italic; */
  color: #555;
  /* margin-bottom: 10px; */
}

/* Comment block */
.comment {
  /* font-style: italic; */
  margin-bottom: 2px;
  /* padding-left: 4rem; */
}

/* Responsive design */
@media (max-width: 768px) {
  .timelineContainer {
    padding-left: 15px;
  }

  .timelineContent {
    padding: 8px 12px;
  }
}

@media (max-width: 576px) {
  .timelineContainer {
    padding-left: 10px;
  }

  .timelineContent {
    padding: 6px 10px;
  }

  .timelineDate {
    font-size: 14px;
  }
}
