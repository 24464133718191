.asset-criticality {
    margin-top: 4rem;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: rgba(105, 250, 225, 1);
}

.verification{
    margin-top: '5rem';
}