
.loader-container.loader-container {
  background: rgba(0, 0, 0, 0.2) !important;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  position: fixed;         /* Stays in place */
  top: 0 !important;
  left: 0 !important;
  z-index: 9999;           /* Ensures it stays on top */
}

.loading {
  /* position: absolute !important; */
  /* flex-direction: row; */
  display: flex !important;

}
