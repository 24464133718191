.ticket-report-attachment-header{
    font-weight: bold;
    text-align: center;
    margin-bottom: 0 !important;
    padding: 7px;
    border-top: 1px solid;
    margin: 0px;
    align-items: center;
}

.ticket-reopen-header{
    font-weight: bold;
    background-color: #548DD4 !important;
    color: white;
    text-align: center;
    border: 1px solid black;
    border-bottom: none;
    padding: 7px;
    border-top: 1px solid black;
    /* Added 'solid' to make it valid */
    margin: 0px;
    align-items: center;
}