.Tabicons {
  padding-left:2px;
  padding-right: 4px;
  size: 20vh;
}
.headings {
  font-weight: bold;
  font-size: 16px;
}
tabs .nav-link:focus {
  background-color: aquamarine !important;
}
tabs .nav-link:active {
  color: #0862B4 !important;
}
.vl {
  border-left: 0.5px solid #ccc !important;
  height: 2% !important;
}
.word {
  /* width: auto; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.custom-button {
  background-color: #69FAE1 !important; /* Replace with your desired custom color */
  color: black !important;
  font-size: 16px !important;
   /* Replace with the desired text color */
}

.custom-button:hover {
  background-color: #69FAE1 !important; /* Replace with the desired hover color */
}