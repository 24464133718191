.menu-item {
  background: white;
  color: #282c34;
  margin: 10px;
  padding: 0 0 10px 0;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(224, 173, 80, 1);
  padding-bottom: 1rem;
}

.menu-item h2 {
  font-size: 0.8rem;
  font-weight: bolder;
  margin: 10px 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.menu-logo {
  width: 100%;
  height: 75%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.menu-item:hover {
  transform: scale(1.05);
}

.menu-withoout-logo {
  width: 100%;
  height: 75%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  background-color: rgb(237, 225, 225);
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-withoout-logo p {
  color: gray;
  font-size: larger;
}
