@font-face {
  font-family: 'icomoon';
  src:  url('../src/medias/fonts/icomoon.eot?t816rz');  
  src:  url('../src/medias/fonts/icomoon.eot?t816rz#iefix') format('embedded-opentype'),
    url('../src/medias/fonts/icomoon.ttf?t816rz') format('truetype'),
    url('../src/medias/fonts/icomoon.woff?t816rz') format('woff'),
    url('../src/medias/fonts/icomoon.svg?t816rz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-V1-Add-dark-mode:before {
  content: "\e900";
  color: #64ffe2;
}
.icon-V1-assets-icon:before {
  content: "\e901";
}
.icon-V1-bulk-upload-dark-mode:before {
  content: "\e902";
  color: #64ffe2;
}
.icon-V1-customers-icon:before {
  content: "\e903";
}
.icon-V1-edit-dark-mode:before {
  content: "\e904";
  color: #64ffe2;
}
.icon-V1-delete:before {
  content: "\e905";
}
.icon-V1-edit-light-mode:before {
  content: "\e906";
  color: #0063b9;
}
.icon-V1-grid-view-dark-mode:before {
  content: "\e907";
  color: #64ffe2;
}
.icon-V1-grid-view-light-mode:before {
  content: "\e908";
  color: #0063b9;
}
.icon-V1-list-view-dark-mode:before {
  content: "\e909";
  color: #64ffe2;
}
.icon-V1-list-view-light-mode:before {
  content: "\e90a";
  color: #0063b9;
}
.icon-V1-location-dark-mode:before {
  content: "\e90b";
  color: #fff;
}
.icon-V1-location-light-mode:before {
  content: "\e90c";
  color: #0063b9;
}
.icon-V1-Next-dark-mode:before {
  content: "\e90d";
}
.icon-V1-Next-light-mode:before {
  content: "\e90e";
}
.icon-V1-notification:before {
  content: "\e90f";
  color: #fff;
}
.icon-V1-overview:before {
  content: "\e910";
}
.icon-V1-previous-dark-mode:before {
  content: "\e911";
}
.icon-V1-previous-light-mode:before {
  content: "\e912";
}
.icon-V1-QR-generator-icon:before {
  content: "\e913";
}
.icon-V1-ratings:before {
  content: "\e914";
  color: #f5c60d;
}
.icon-V1-Schedule-report-icon:before {
  content: "\e915";
}
.icon-V1-sites:before {
  content: "\e916";
}
.icon-V1-switch-to-dark-mode--icon:before {
  content: "\e917";
  color: #fff;
}
.icon-V1-switch-to-light-mode-icon:before {
  content: "\e918";
  color: #fff;
}
.icon-V1-upload-dark:before {
  content: "\e919";
  color: #64ffe2;
}
.icon-V1-upload-light:before {
  content: "\e91a";
  color: #0063b9;
}
.icon-V1-web-url-dark-mode:before {
  content: "\e91b";
  color: #64ffe2;
}
.icon-V1-web-url-light-mode:before {
  content: "\e91c";
  color: #0063b9;
}
