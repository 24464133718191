.user-profile-container {
    color: #000;
    margin-right: 20px;
}

.user-icon {
    max-height: 100px;
    cursor: pointer;
    margin-top: 8px;
}

.user-name {
    /* font-weight: bolder;
    color: black;
    margin-right: 21px;
    font-size: 15px; */

    font-weight: bolder;
    color: black;
    /* margin-right: 21px; */
    font-size: 15px;
    padding-right: 15px;
    padding-top: 7px;
}


.dropdown-btn {
    border: none;
    background: none;
    color: #2D7DC1;
}

.dropdown-icon {
    max-height: 100px;
    cursor: pointer;
    margin-top: 8px;
}

.profile-btn,
.logout-btn {
    text-decoration: none;
    color: black;
    text-align: center;
    border: none;
    background: none;
}