.ins-work-report-main-div {
    margin: auto;
    width: 1000px !important;
    margin-bottom: 50px;
    display: block;
}

.main-div{
    margin-bottom: 50px;
    page-break-after: always;
}



.ins-work-report-main-div>div {
    page-break-after: always;
}


.report-header-table {
    margin: auto;
    width: 1000px !important;
    border: 1px solid black !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    border-collapse: collapse;
    table-layout: fixed;
    margin-left: auto;
    margin-top: 0px;
}

.report-header-table>thead {
    width: 100%;
}

.report-header-table-logo {
    border: 1px solid;
    width: 200px;
    text-align: center;
    vertical-align: middle;
}

.report-header-table-logo-image {
    width: 150px;
    align-items: center;
}

.report-header-table-content {
    border: 1px solid;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
}

.report-header-table-content-span {
    font-weight: bold;
    font-size: 16px;
}


.report-asset-details {
    font-weight: bold;
    background-color: #0862B4 !important;
    color: white !important;
    text-align: center;
    border: 1px solid black;
    border-bottom: none;
    padding: 7px;
    border-top: 1px solid black;
    /* Added 'solid' to make it valid */
    margin: 0px;
    align-items: center;
}

.report-additional-comment {
    text-align: left;
    padding: 7px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0px;
    align-items: center;
    word-wrap: break-word;
    font-weight: normal;
}

.report-additional-comment>span {
    font-weight: bold;
}

.download-btn{
    top: 13%;
    right: 1%;
}

.report-pdf >div{
    margin-top: 60px;
}

.report-container{
    page-break-after: always !important;
}

@media (max-width: 1050px) {
    .ins-work-report-main-div {
        display: none;
    }

    .ins-work-download-btn {
        flex-direction: column !important;
    }

    .report-responsive {
       display: none;
    }
    .work-order-report-btn{
        width: 100px !important;
    }
}



@media print {
    .report-asset-details {
        background-color: #0862B4 !important;
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /* Standard */
    }
    .report-responsive {
        display: block;
    }
    .container{
        display: none;
    }
    .download-title{
        display: none !important;
    }
    .download-custom-btn-div{
        display: none !important;
    }
    .listHeadings{
        display: none;
    }
    
}