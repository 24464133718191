
.modal-body-div {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    /* font-family: 'Arial, sans-serif'; */
}

.modal-body-div>div {
    width: 45%;
}

.select-site-list {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%,
}

.select-site-list-input {
    width: 100%;
    padding: 8px;
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
}

.select-all-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px
}

.select-all-div>p {
    margin: 0;
    font-size: 10px;
    margin-top: 1px;
}

.select-all-div>input {
    /* margin-bottom: 15px; */
    margin-right: 5px;
    width: 10px;
    margin-left: 5px;
}

.select-site-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: scroll;
}

/* .select-site-ul::after {
    content: "";
    display: block;
    height: calc(200px - 100%); 
} */

.select-site-ul>li {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 5px;
    transition: background 0.3s;
    cursor: pointer;
}

.select-site-ul>li>label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    font-size: 10px;
}

.select-site-ul>li>label>input {
    margin-right: 10px;
    transform: scale(1.2);
}

.selected-site-list>label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.selected-site-list>ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    max-height: 260px;
    overflow-y: auto;
}

.selected-site-list>ul>li {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 10px;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
}

.selected-site-list>ul>li>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.selected-site-list>ul>li>div>div {
    margin-right: 10px;
    font-size: 10px;
}

.selected-site-list>ul>li>div>div>span {
    margin-right: 10px;
    font-size: 10px;
}

.icon-container {
    margin-right: 15px;
    /* font-family: "Poppins", "Calibri", "Times New Roman", sans-serif; */

}

.site-icon {
    color: #64FFE2;
    cursor: pointer;
    margin-top: 6px;
}

.icon-badge {
    position: absolute;
    top: 1px;
    left: 7px;
    background-color: #4682B4;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* font-family: "Poppins", "Calibri", "Times New Roman", sans-serif ; */
}