.ins-statusChart {
    margin-top: 3.75rem;
}

.maint-statusChart {
    margin-top: 1rem;
}

.onDemand-statusChart {
    margin-top: 1rem;
    padding-left: 0.5rem;
}

.ticket-statusChart {
    margin-top: 1rem;
    padding-left: 0.5rem;
}

.my-date-range-picker {
    height: 100%;
}

.overflowX {
    overflow-x: hidden;
    overflow-y: hidden;
}

.dahboardStatus {
    font-weight: bold;
    /* padding: 1px 12px; */
    width: 100%;
    border: none;
    border-radius: 20px;
    /* background: linear-gradient(
      90deg,
      rgba(105, 250, 225, 1) 59%,
      rgba(72, 127, 173, 1) 100%
    ); */
}

.status-div {
    width: 95%;
    margin: auto;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.1rem 1rem;
}

.status-heading {
    font-weight: bold;
    font-size: 18px;
    margin: 1rem 0;
}