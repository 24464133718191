.react-datepicker-wrapper {
    width: 100% !important;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .datepicker-button {
    width: 100% !important;
    height: 38px;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #ccc;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.8rem !important;
    box-sizing: content-box;
}  