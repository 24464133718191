.back-btn{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    cursor: pointer;
    position: fixed;
    top: 60px;
    left: 20px;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px;
}

@media print {
    .back-btn{
        display: none;
    }
}