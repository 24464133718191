.countcardcontainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 6rem;

    @media screen and (max-width: 1080px) {
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        /* margin: 1rem; */
        align-items: center;
    }
}

.countcard {
    background-color: #f6f4f4c9;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 6px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    min-width: 20rem;
    /* min-height: 6rem; */
    max-height: 8rem;
    /* width: 20rem; */

    @media screen and (max-width: 1080px) {
        margin: 1rem;

    }
}

.countcard .titletext {
    font-size: 14px;
    font-weight: bold;
    color: #6f6c6c;
    margin: 0;
    padding: 0;
}

.graphname {
    font-size: 18px;
    font-weight: bold;
    color: #6f6c6c;
    margin: 0;
    padding: 0;
    margin-top: 8px;
    /* padding-left: 1rem; */

}

.counttext {
    font-size: 22px;
    font-weight: bold;
    color: #3a3838;
    margin: 0;
    padding: 0;
    /* border: 1px solid #6f6c6c; */
    margin-top: 8px;
    /* padding-left: 1rem; */
}