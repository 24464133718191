.reset-style,
.reset-style * {
    all: revert;
}
#html,
::after,
::before {
    box-sizing: content-box !important;
}
#html {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    /* overflow-anchor: none; */
    /* font-weight: 600; */
}
#checkListHeader{
    margin-bottom: 8px !important;
}