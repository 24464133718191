.paiChart-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: -50px;
    margin-top: 40px;
}

.chart-container {
    height: 430px;
    width: 480px ;
    margin-top: 10px;
    margin: auto;
    text-align: center;
    border: 1px solid rgba(105, 250, 225, 1);
    border-radius: 1rem;
    overflow: hidden;
}


.chart-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 15px;
}

@media (max-width:550px) {
    .chart-container {
        height: 430px;
        width: 90%;
        margin: auto;
        margin-top: 10px;
        text-align: center;
        border: 1px solid rgba(105, 250, 225, 1);
        border-radius: 1rem;
        overflow: hidden;
    }
}
