body {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.container {
  /* margin: auto; */
  display: flex;
  justify-content: space-between; /* Align boxes horizontally with equal spacing */
  align-items: center;
  height: 100vh;
  /* padding: 0 12rem; */
  /* background-color: rgb(255, 255, 255); */
  /* background-color: cyan; */
  gap: 2%;
}

.box {
  width: 25vw; /* Set width as a percentage of viewport width */
  height: 25vw; /* Set height same as width to maintain a perfect circle */
  background-color: lightblue;
  margin: 5px;
  border-radius: 10%;
  text-align: center;
  /* margin: auto; */
  align-content: center;
  /* background-color: #c0b19c; */
  background-color: #e1a364;
  color: white;
  border-bottom: 3px solid rgb(70, 67, 67);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

@media (max-width: 720px) {
  .container {
    flex-direction: column; /* Change layout to column for mobile devices */
    justify-content: space-evenly;
    padding: 0 5rem; /* Adjust padding for mobile view */
    margin-top: 4rem;
    /* margin: auto; */
  }

  .box {
    width: 65vw; /* Set width as a percentage of viewport width for mobile view */
    height: 75vw; /* Set height same as width to maintain a perfect circle */
  }
}
