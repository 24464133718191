
.download-btn-custom{
    top: 15%;
    right: 1%;
    position: absolute;
}

.date-filter-custom-report{
    border:1px solid #CACACA ;
    font-size: 15px;
    padding: 5px;
    width: 90% ;
    margin-bottom: 5px;
}

.download-title{
    display: none;
}

.filter-div{
    margin-top: 80px;
}

@media (max-width: 1050px)  {
    .filter-div{
        display: block !important;
        margin-top: 20px;
    }
    .filter-div>div{
        width: 90% !important;
        margin: auto;
    }
    .custom-report-main-div{
        display: block !important;
    }
    .download-btn-custom {
        position: static; /* Remove absolute positioning */
        display: block;
        margin: 20px auto; /* Center horizontally */
        text-align: center;
        margin-top: 20px;
    }
    .date-filter-custom-report{
        width:100%
    }
    .download-title{
        display:block;
        text-align: center;
        width: 60%;
        margin: auto;
    }
}

@media print {
    .nabar-div {
        display: none;
    }
    .filter-div{
        display:none !important;
    }
    
}