.mainContainer {
  text-align: center;
  /* background-color: #000000; */
  background-color: #ffffff;
  height: 95vh;
  font-weight: bolder;
  font-family: Arial !important;
}

.app-header {
  background-color: #ffffff;
  /* background-color: #000000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  color: black;
  height: 100%;
  /* margin-bottom: 5rem; */
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 780px) {
  /* .menu {
    flex-direction: column;
  } */
  /* .App-header {
    margin-bottom: 5rem;
    margin-top: 8rem;
  } */
}
