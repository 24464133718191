.parentContainer {
  margin: auto;
  /* border: 1px solid; */
}

.headingBackgroundContainer {
  width: 100%;
  background-color: rgb(250, 241, 241);
  min-height: 250px;
  margin: auto;
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
}
.headingDiv {
  margin: auto;
  /* border: 1px solid; */
  text-align: center;
  margin-bottom: 0;
}
.dataContainer {
  margin: auto;
  max-width: 70%;
  margin-top: 4rem;
}
.contactBackgroundContainer {
  width: 100%;
  background-color: rgb(250, 241, 241);
  min-height: 250px;
  margin: auto;
  /* border: 1px solid; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* text-align: center; */
}
/* style={{ padding: '1rem', paddingLeft: '5rem', backgroundColor: 'black' }} */
@media (min-width: 721px) {
  .logo {
    padding: 1rem;
    padding-left: 5rem;
    background-color: black;
  }
}
@media (max-width: 720px) {
  .logo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: black;
    display: flex;
    justify-content: center;
  }
  .dataContainer {
    margin: auto;
    max-width: 90%;
    margin-top: 4rem;
  }
}
