.maint-report-div{
    margin: auto;
    width: 1000px;
    margin-bottom: 50px;
    page-break-after: always;
}

.maint-activity-table{
    width: 1000px !important;
    margin:auto;
    border: 1px solid black;
    table-layout: fixed;
    margin-bottom: 0px !important;
}            

.maint-activity-table-header{
    word-wrap: break-word;
    width: 70px;
    border: 1px solid black;
    text-align: center;
}

.maint-activity-table-performer{
    margin: auto;
    width: 1000px !important;
    border: 1px solid black;
    table-layout: fixed;
    margin-bottom: 0px !important;
}

.maint-activity-table-performer-comment{
    text-align: left;
    padding: 7px;
    border: 1px solid black;
    margin:0px;
    margin-bottom: 0px !important;
    align-items: center;
    word-wrap: normal;
    font-weight: normal;
}

.maint-activity-table-performer-details{
    margin: auto;
    width: 1000px !important;
    border: 1px solid black !important;
    margin-bottom: 0 !important;
    table-layout: fixed;
    margin-left: auto;
}

