.asset-filter-div {
    display: flex;
    justify-content: flex-end;
    /* Equivalent to "right" */
    margin: 20px !important;
    gap: 5% !important;
}

.workorder-header-main-div {
    text-align: center;
}

.workorder-header {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.workorder-search {
    border: 1px solid steelblue;
    font-size: 15px;
    padding: 4px;
}

.workorder-search-clear {
    padding-left: 8px;
    margin-right: 25px;
}

.workorder-date {
    border: 1px solid steelblue !important;
    font-size: 15px;
    padding: 5px;
    margin: 5px;
}

.date-div {
    margin-left: 13px;
}

.view-report-btn {
    width: 150px
}

.status-result-div {
    /* border: 1px solid; */
    width: 95% !important;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.status-result-div>p{
    width: 40%;
}


.showing-result{
    text-align: right;
}

.status-result-div>div {
    float: right;
    /* border: 1px solid; */
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.workorder-table-header {
    text-align: center;
    background-color: steelblue !important;
    padding: 8px;
    color: #ffff
}

.workorder-table-selection {
    padding: 8px !important;
    width: 50px;
}

.workorder-table-sno {
    padding: 8px !important;
    text-align: center;
}

.common-styling-th {
    text-align: left;
    padding-left: 5px !important;
}

.tbody {
    padding: 15px !important;
}

.table-body-response {
    padding: 20px !important;
}

.table-body-sitename {
    text-align: left;
    padding-left: 5px !important;
    padding-right: 5px;
}

.pdf-preview-btn {
    border: none !important;
    border-radius: 10px;
    background-color: #E8F5F3;
}

.no-data {
    text-align: center;
    height: 75px !important;
}

.pagination-div {
    padding-right: 25px !important;
}

.pagination-btn {
    padding: 5px !important;
    cursor: pointer;
    border: none;
    background-color: #ffff;
}

.pagination-limit-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
}

.pagination-limit-select {
    border-radius: 8px;
    cursor: pointer;
}


.pagination-showing {
    padding: 10px;
}




/* Modern Card Styling */
/* Card Styling */
/* Card Styling */
.card-data {
    /* background: ; Light turquoise background */
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-data:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Card Header */
.custom-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #4682B4;
    /* Darker blue border */
    padding-bottom: 12px;
}

.serial-number {
    font-size: 18px;
    font-weight: 700;
    color: black;
    /* Dark blue for the serial number */
}

/* Card Body Content */
.custom-card-content {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.key {
    font-size: 14px;
    font-weight: bold;
    color: black;
    text-align: left;
    /* Dark blue for keys */
}

.value {
    font-size: 14px;
    font-weight: 500;
    color: black;
    /* Slightly darker shade of blue */
    text-align: right;
    word-break: break-all;
}

/* Action Section */
.action-row {
    border-top: 2px solid #4682B4;
    /* Darker blue border */
    padding-top: 10px;
}

.pdf-preview-btn-mobile-div {
    width: 100%;
}

.pdf-preview-btn-mobile {
    background-color: #69FAE1;
    /* Dark blue button */
    /* color: #69FAE1; */
    margin: auto;
    /* Light turquoise text on the button */
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s ease-in-out;
}

.pdf-preview-btn-mobile:hover {
    background-color: #1f4e74;
    /* Slightly darker shade of dark blue on hover */
}

/* Small screen adjustments */
.d-md-none {
    background-color: #f4f6f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tab-spacing {
    margin-right: 8px !important;
}