/* Navbar Styling */
.navbar1 {
    position: fixed;
    /* Keeps it fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    /* Full width */
    display: flex;
    height: 38px;
    justify-content: space-between;
    align-items: center;
    padding: 3px 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f8f9fa;
    z-index: 1050;
    /* Higher than sidebar to stay on top */
}

/* Left Section */
.leftSection {
    display: flex;
    align-items: center;
    height: 100%;
}

/* Hamburger Button */
.hamburger {
    font-size: 24px;
    margin-right: 15px;
    background: none;
    border: none;
    cursor: pointer;
}

/* Logo */
.logo {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px !important;
    padding-left: 8px !important;
}

/* Logout Button */
/* .logoutButton {
    background: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
} */

.logoutbtn{
    margin-bottom: 39px !important;
}

@media print {
    .nabar-div {
        display: none;
    }
}