.status-card-div{
    width: 16rem !important;
    height: 7rem;
    border-radius: 0.6rem;
    cursor: pointer;
    background-color: white;
    padding-right: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.percentage-div{
    display: flex;
    padding-right: 0.5rem;
    align-items: baseline;
}

.dynamic-text {
    margin: 0;
    padding: 0.3rem 0.1rem 0 0.5rem;
    color: #454545;
}

/* Additional classes for dynamic font sizes */
.small-font {
    font-size: 13px;
}

.large-font {
    font-size: 36px;
}

.count {
    margin: 0;
    font-size: 14px;
    padding: 0 0 0 0.5rem;
    color: gray;
    vertical-align: bottom;
}

.font-small {
    font-size: 14px;
}

.font-large {
    font-size: 16px;
}

.status-title {
    margin: 0;
    padding: 0 0 0.2rem 0;
    color: gray;
}

.inspection-card{
    width: 95% ;
    padding: 0.7rem 0;
    margin: auto !important;
}

.card-name{
    font-size: 18px;
    flex: 1 !important;
}

.inspection-card-collapsed{
    font-size: 18px;
    margin-bottom: 20px;
}

.inspection-card-expanded{
    background-color: 'white';
    border-radius: 0.5rem;
}

.clear-btn{
    height: 2rem;
    border: none;
    border-radius: 0.5rem;
    background-color: lightblue !important;
    padding: 5px;
}

.full-width-datepicker{
    width: 100%
}

.custom-date-input{
    width: 100%;
    cursor: pointer;
    background-color: #fff !important;
}