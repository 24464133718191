* {
  margin: 0%;
  padding: 0%;
}


.bg {
  width: 100%;
  min-height: 100vh; /* Ensure it takes at least the full viewport height */
  height: auto; /* Adjust based on content */
  position: relative; /* Allows for scrolling content without fixing the background */
  background-image: url(../../medias/images/login_bg.png);
  background-color: #060f2d;
  background-size: cover; /* Ensure the background image covers the full screen */
  background-position: center; /* Keeps the background image centered */
  overflow: hidden; /* Hide overflow by default */
}
.zongoLogo {
  width: 55%;
  height: 20%;
  text-align: center !important;
  margin-left: 20%;
}
.form-container {
  border-radius: 16px;
  width: 100%;
  max-width: 400px;
  margin: 120px auto;
  background: #fff;
  -webkit-box-shadow: 0px 0px 96px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 96px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 96px 0px rgba(0, 0, 0, 0.75);
  min-height: 150px;
  padding: 35px;
}

.loginInput {
  border-radius: 8px;
  padding: 12px 20px;
  height: 38px;
  border: none !important;
  width: 100%;
  background: rgba(0, 99, 185, 0.15);
}
input:focus {
  outline: none !important;
}
.form-label {
  font-weight: 500;
  padding-top: 2rem;
  padding-bottom: 1rem;
  float: left;
}
.welcome {
  font-size: 20px;
  padding-bottom: 8px;
  font-weight: 700;
}

.forgtPass {
  color: #0063b9;
  background: none !important;
  border: none !important;
  padding-top: 0rem;
  padding-bottom: 0.5rem;
}
.form-text {
  color: black;
  float: left;
}

.btn {
  float: left;
}

.SignInButton {
  height: 38px;
  font-weight: bold;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-top: 2rem;
  background: linear-gradient(
    90deg,
    rgba(105, 250, 225, 1) 59%,
    rgba(72, 127, 173, 1) 100%
  );
}

.eyeIcon {
  color: gray;
  cursor: pointer;
  margin-left: 88%;
  position: relative;
  top: -30px;
}


.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  color: white;
  font-size: 12px;
  text-align: center;
  z-index: 1000;
  /* background-color: #000; Ensure visibility */
}